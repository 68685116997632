<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="contractPage" :fields="fields" :search-model="searchModel"
                :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="签署状态">
            <el-option v-for="(label, value) in flowStateMap" :key="value" :label="label" :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="webTimeSearch">
          <el-date-picker v-model="searchModel.webTimeSearch" type="date" placeholder="发起时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="webSearch" style="width: 150px">
          <el-input v-model="searchModel.webSearch" placeholder="签署方 手机号 姓名" style="width: 150px"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleAdd" size="mini" type="primary">发起合同</el-button>
      </template>
    </page-table>
    <el-dialog title="发起合同" :visible.sync="page_dialog_visible" append-to-body :close-on-click-modal="false"
               width="35%" custom-class="min-w-750">
      <div v-loading="page_form_loading">
        <el-form :inline="true" @submit.native.prevent class="page-searcher-form" label-width="80px"
                 ref="pageSearchRef" size="mini">
          <div class="show-more page-searcher-items" ref="searchItemsRef">
            <slot name="search-items"></slot>
            <el-form-item label="农户">
              <el-input v-model="pageSearchModel.webFarmerSearch" placeholder="农户姓名/手机号/身份证"></el-input>
            </el-form-item>
            <el-form-item class="search-actions">
              <el-button @click="pageSearchSubmit" type="primary">查找</el-button>
            </el-form-item>
          </div>
        </el-form>
        <page-table ref="pageTable" show-paginate :get-data="adminProjectPage" :fields="pageFields"
                    :search-model="pageSearchModel" :show-search="false" :show-header="false" :no-gutter="true"
                    @onCurrentChange="projectChange">
          <template slot="search-items">
            <el-form-item label="业务员" prop="name">
              <el-input v-model="pageSearchModel.name" placeholder="业务员"></el-input>
            </el-form-item>
          </template>
        </page-table>
        <div style="padding: 20px 0;">
          <span>选择：</span>
          <span v-if="currentProject">{{ currentProject.farmerName }} {{ currentProject.farmerPone }}</span>
          <span v-else>未选择农户</span>
        </div>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary" :disabled="currentProject == null">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="合同编辑" :visible.sync="edit_dialog_visible" append-to-body
               :close-on-press-escape="false" :close-on-click-modal="false" width="50%">
      <el-form :model="editForm" :rules="editRules" label-width="200px" ref="editForm"
               v-loading="edit_form_loading" v-if="currentProject">
        <el-form-item label="开发商">
          {{ currentProject.developers ? currentProject.developers.name : '' }}
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="农户姓名">{{ currentProject.farmerName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="农户电话">{{ currentProject.farmerPhone }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同模板" prop="templateId">
              <el-select v-model="editForm.templateId" placeholder="合同模板" @change="templateChange">
                <el-option v-for="item in template_options" :key="item.templateId" :disabled="item.templateType != '1'"
                           :label="item.templateName" :value="item.templateId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同类型" prop="type">
              <el-select v-model="editForm.type" placeholder="请选择合同类型">
                <el-option :label="label" :value="value" v-for="(label,value) in typeMapData" :key="label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-for="(formField, index) in editForm.formFields">
          <el-form-item :label="formField.componentDescription" :prop="'formFields.' + index" :key="index"
                        v-if="formField.is_show">
            <!--      :rules="formField.componentRequired ? [{required: true, message: '请输入', trigger: 'blur'},] : []">-->
            <el-input v-model="editForm.formFields[index].componentValue" :placeholder="formField.componentDescription"
                      v-if="formField.componentType === 'TEXT' && formField.componentName != 'zncapacity'"
                      :type="formField.type">
              <template slot="append" v-if="formField.unit != ''">{{formField.unit}}</template>
            </el-input>
            <el-input v-if="formField.componentType === 'TEXT' && formField.componentName === 'zncapacity'"
                      placeholder="系统自动生成" disabled>
              <template slot="append" v-if="formField.unit != ''">{{formField.unit}}</template>
            </el-input>
            <el-input v-model="editForm.formFields[index].componentValue" :placeholder="formField.componentDescription"
                      v-if="formField.componentType === 'MULTI_LINE_TEXT'" type="textarea" :rows="3"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="地址" prop="areas" v-if="show_region">
          <el-cascader ref="areaCascaderRef" v-model="region" :options="pca_options"
                       @change="areaChange($event)"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address" v-if="show_region">
          <el-input v-model="address" placeholder="详细地址"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="edit_dialog_visible = false">取 消</el-button>
          <el-button @click="editSubmit('editForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="微信扫码签署合作协议" :visible.sync="sign_dialog_visible" append-to-body
               :close-on-click-modal="false" width="50%">
      <div style="display: flex;justify-content: center;">
        <img :src="BASE_URI + '/code?code=' + signUrl" alt="" style="max-width: 400px;height: auto;">
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="sign_dialog_visible = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import pcaCode from "@/utils/pca-code"
import {contractPage, adminProjectPage, contractTemplateAll, contractFlow, contractAgain} from "@/api/common"
import {BASE_URI} from "@/utils/config"

const typeMap = {
  "02": "屋顶租赁",
  "03": "全款销售",
  "01": "个人贷款",
}
const flowStateMap = {
  INIT: '创建',
  PART: '签署中',
  ALL: '签署完成',
  REJECT: '拒签',
  CANCEL: '撤回',
  WILLEXPIRE: '即将过期',
  DEADLINE: '流签',
  EXCEPTION: '异常',
}

export default {
  name: "Contracts",
  data() {
    return {
      BASE_URI: BASE_URI,
      pca_options: pcaCode,
      searchModel: {
        webTimeSearch: '',
        state: '',
        webSearch: '',
      },
      typeMapData: typeMap,
      contractPage: contractPage,
      adminProjectPage: adminProjectPage,
      fields: [
        {label: '合同名称', key: 'id', width: 200},
        {
          label: '发起方', width: 200,
          render({developers}, h) {
            return h('div', developers ? developers.name : '')
          }
        },
        {
          label: '签署方', width: 200,
          render({project}, h) {
            return h('div', project ? project.farmerName : '')
          }
        },
        {
          label: '签署方证件号码', width: 180,
          render({project}, h) {
            return h('div', project ? project.farmerIdNo : '')
          }
        },
        {
          label: '手机号', width: 120,
          render({project}, h) {
            return h('div', project ? project.farmerPhone : '')
          }
        },
        {
          label: '签署状态', width: 100,
          render({state}, h) {
            return h('div', flowStateMap[state])
          }
        },
        {
          label: '合同类型', width: 120,
          render({project}, h) {
            if (project) {
              return h('div', project.type ? typeMap[project.type] : '')
            }
          }
        },
        {label: '发起时间', key: 'time', width: 180, align: 'center'},
        {label: '签署时间', key: 'singTime', width: 180, align: 'center'},
      ],
      actions: [
        // {action: 'reLaunch', label: '催签'}, // 没有
        {
          action: 'reLaunch',
          label: '重新发起',
          type: 'primary',
          showAction(item) {
            return ['REJECT', 'CANCEL', 'DEADLINE', 'EXCEPTION'].indexOf(item.state) >= 0
          }
        },
        {
          action: 'view',
          label: '查看合同',
          type: 'primary',
          showAction(item) {
            return item.state == 'ALL'
          }
        },
      ],
      flowStateMap: flowStateMap,
      page_form_loading: false,
      pageForm: {
        project_id: '',
      },
      pageRules: {
        project_id: [
          {required: true, message: '请选择项目', trigger: 'blur'},
        ],
      },
      page_dialog_visible: false,
      pageFields: [
        {
          label: '服务商',
          render({developers}, h) {
            return h('div', developers ? developers.name : '')
          },
        },
        {label: '农户姓名', key: 'farmerName', width: 120},
        {label: '农户电话', key: 'farmerPhone', width: 170, align: 'center'},
        {label: '业务员', key: 'name', width: 120},
      ],
      pageSearchModel: {
        webFarmerSearch: ''
      },
      currentProject: null,
      //
      edit_form_loading: false,
      editForm: {
        templateId: '',
        type: '',
        formFields: []
      },
      editRules: {
        templateId: [
          {required: true, message: '请选择合同模板', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择合同类型', trigger: 'blur'},
        ],
      },
      edit_dialog_visible: false,
      template_options: [],
      currentTemplate: null,
      //
      signUrl: null,
      sign_dialog_visible: false,
      // 不显示的字段
      notShowArr: [
        "zncontractno", "zndevelopername", "znunifiedcreditcode", "znfarmeraddress", "zndeveloperaddress", "zndeveloperphone",
        "znsum", "znstartmonth", "znmonthprofit", "znyearprofit", "znnextstartmonth", "znhasstartmonth", "znhasendmonth",
        "znendmonthprofit", "znendyearprofit","znfdevelopername","zncontractno2",
      ],
      numberArr: ['znsize', 'znbankno'],
      digitArr: ['znnorms', 'znprice', 'zncapacity'],
      show_region: false,
      region: [], // 显示地址
      address: '', // 详细地址
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      let that = this
      that.currentProject = null
      that.page_dialog_visible = true
      setTimeout(() => {
        that.$refs.pageTable && that.$refs.pageTable.loadData(true)
      }, 200)
    },
    pageSearchSubmit() {
      this.$refs.pageTable && this.$refs.pageTable.loadData(true)
    },
    projectChange(val) {
      this.currentProject = val
      if (val.wordOrder && val.wordOrder.farmer) {
        this.region = [val.wordOrder.farmer.province, val.wordOrder.farmer.city, val.wordOrder.farmer.area]
        this.address = val.wordOrder.farmer.address
      } else {
        this.region = []
        this.address = ''
      }
    },
    submit() {
      if (this.currentProject == null) {
        this.$message.error('请选择农户')
        return false
      }
      this.page_dialog_visible = false
      this.getTemplateOptions()
      this.editForm = {
        templateId: '',
        type: '',
        formFields: []
      }
      this.edit_dialog_visible = true
    },
    getTemplateOptions() {
      let that = this
      contractTemplateAll().then(res => {
        that.template_options = res
      })
    },
    areaChange(val) {
      this.region = val
    },
    templateChange(val) {
      let that = this
      let currentTemplate = this.template_options.find(item => {
        return item.templateId == val
      })
      that.currentTemplate = currentTemplate
      that.editForm.formFields = []
      for (let i in that.currentTemplate.components) {
        let item = {
          componentDescription: this.currentTemplate.components[i].componentDescription,
          componentName: this.currentTemplate.components[i].componentName,
          componentValue: '',
          componentType: this.currentTemplate.components[i].componentType,
          componentRequired: this.currentTemplate.components[i].componentRequired,
          is_show: that.notShowArr.indexOf(that.currentTemplate.components[i].componentName) < 0,
          unit: '',
          type: 'text'
        }
        if (item.componentName == 'znfarmeraddress') {
          that.show_region = true
        }
        if (item.componentName == 'znfarmername') {
          item.componentValue = that.currentProject && that.currentProject.farmerName || ''
        }
        if (item.componentName == 'zndevelopername') {
          item.componentValue = that.currentProject && that.currentProject.farmerName || ''
        }
        if (item.componentName == 'znidno') {
          item.componentValue = that.currentProject && that.currentProject.farmerIdNo || ''
        }
        if (item.componentName == 'znfarmerphone') {
          item.componentValue = that.currentProject && that.currentProject.farmerPhone || ''
        }
        // input type
        if (that.numberArr.indexOf(item.componentName) > -1) {
          item.type = 'number'
        } else if (that.digitArr.indexOf(item.componentName) > -1) {
          item.type = 'digit'
        } else {
          item.type = 'text'
        }
        // 组件规则单位： Wp
        if (item.componentName == 'znnorms') {
          item.unit = 'Wp'
        }
        // 装机容量单位： W
        if (item.componentName == 'zncapacity') {
          item.unit = 'W'
        }
        // 产品单价单位： W
        if (item.componentName == 'znprice') {
          item.unit = '元'
        }
        that.editForm.formFields.push(item)
      }
    },
    editSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) return false
        that.edit_form_loading = true
        if (that.editForm.formFields.length > 0) {
          for (let item of that.editForm.formFields) {
            if (item.componentName == 'znfarmeraddress') {
              item.componentValue = `${that.region.join('')}${that.address}`
            }
          }
        }
        contractFlow(that.currentProject.id, that.editForm.type, that.editForm).then(res => {
          that.edit_form_loading = false
          that.$message.success('提交成功')
          that.edit_dialog_visible = false
          that.$refs.table.loadData()
          that.signUrl = encodeURIComponent(res.signUrl)
          that.sign_dialog_visible = true
        }).catch(() => {
          that.edit_form_loading = false
        })
      })
    },
    reLaunchAction(item) {
      let that = this
      that.$confirm('重新发起签约, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        contractAgain(item.id).then(res => {
          that.$message.success('操作成功')
          that.$refs.table.loadData()
          that.signUrl = encodeURIComponent(res.signUrl)
          that.sign_dialog_visible = true
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    viewAction(item) {
      window.open(BASE_URI + item.localUrl, '_black')
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
